<template>
  <div>
    <error v-if="not_found" />
  </div>
</template>

<script>
import Error from '@/views/errors/404'

export default {
  components: {
    Error
  },

  mounted() {
    if(! this.$auth.hasToken()) {
      this.$router.push({ name: 'public.home' })
    } else {
      let user = this.$store.getters.getAuthUser

      if(user.role == 'guest') {
        return this.$router.push({ name: 'guest.home' })
      }
      else if(user.role == 'trader') {
        return this.$router.push({ name: 'trader.home' })
      }
      else if(user.role == 'moderator') {
        return this.$router.push({ name: 'moderator.home' })
      }
      else if(user.role == 'affiliate') {
        return this.$router.push({ name: 'affiliate.home' })
      }
      else if(user.role == 'admin') {
        return this.$router.push({ name: 'admin.home' })
      } else {
        this.not_found = true
      }
    }
  },

  data() {
    return {
      not_found: false
    }
  }
}
</script>